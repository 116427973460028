import { useFetch } from '@/client.js'

class ConfigService {
  get = () => useFetch('configuration').get().json()
  saveCompany = (company) => useFetch('configuration/company').put({ company: company })
  saveLanguage = (language) => useFetch('configuration/language').put({ language: language })
  saveOpeningHours = (openingHours) => useFetch('configuration/opening-hours').put({ openingHours: openingHours }).json()
  updateNotificationSettings = (settings) => useFetch('configuration/notifications').patch(settings)
}

export default new ConfigService()
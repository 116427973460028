import { createRouter, createWebHistory } from 'vue-router'
import { getStartingLocale, loadLocaleMessages, setI18nLanguage } from '@/i18n.js'
import { useUserStore } from '@/stores/user.js'
import { el, enUS } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
import { useCustomerStore } from '@/stores/customer.js'
import { useEmployeeStore } from '@/stores/employee.js'
import { useServiceStore } from '@/stores/service.js'
import { useAppointmentStore } from '@/stores/appointment.js'
import { isoDate } from '@/utils/format.js'
import { useNotificationStore } from '@/stores/notification.js'
import { usePaymentStore } from '@/stores/payment.js'
import { useAppStore } from '@/stores/app.js'
import { usePermissionsStore } from '@/stores/permissions.js'

export function setupRouter(i18n) {
  const ifNotAuthenticated = (to, from, next) => {
    const store = useUserStore()
    const permissionsStore = usePermissionsStore()
    if (!store.isAuthenticated) {
      return next()
    }
    if (permissionsStore.can(permissionsStore.Permissions.ViewDayReport)) {
      return next({ name: 'Dashboard' })
    } else {
      return next({ name: 'Appointments' })
    }
  }

  const ifAuthenticated = (to, from, next) => {
    const store = useUserStore()
    if (store.isAuthenticated) {
      return next()
    }
    return next({ name: 'Login' })
  }

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
      // always scroll to top
      return { top: 0 }
    },
    routes: [
      {
        path: '/(login|forgot-password|password-reset|signup)',
        name: 'LoginPages',
        component: () => import('../views/LoginLayout.vue'),
        children: [
          {
            path: '/login',
            name: 'Login',
            component: () => import('../views/LoginMain.vue'),
            beforeEnter: ifNotAuthenticated
          },
          {
            path: '/signup',
            name: 'Signup',
            component: () => import('../views/SignupMain.vue'),
            beforeEnter: ifNotAuthenticated
          },
          {
            path: '/signup/password',
            name: 'SignupPassword',
            component: () => import('../views/SignupPassword.vue'),
            beforeEnter: ifNotAuthenticated
          },
          {
            path: '/signup/company',
            name: 'SignupCompany',
            component: () => import('../views/SignupCompany.vue'),
            beforeEnter: ifNotAuthenticated
          },
          {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: () => import('../views/ForgotPassword.vue')
          },
          {
            path: '/password-reset/:token',
            name: 'PasswordReset',
            component: () => import('../views/PasswordReset.vue')
          }
        ]
      },
      {
        path: '/welcome',
        name: 'Onboarding',
        component: () => import('../views/onboarding/OnboardingLayout.vue'),
        children: [
          {
            path: '',
            name: 'Welcome',
            component: () => import('../views/onboarding/WelcomeScreen.vue')
          },
          {
            path: 'opening-hours',
            name: 'OnboardingOpeningHours',
            component: () => import('../views/onboarding/OpeningHours.vue')
          },
          {
            path: 'services',
            name: 'OnboardingServices',
            component: () => import('../views/onboarding/AddServices.vue')
          },
          {
            path: 'done',
            name: 'OnboardingDone',
            component: () => import('../views/onboarding/FinalStep.vue')
          }
        ]
      },
      {
        path: '/',
        redirect: '/dashboard',
        component: () => import('../views/MainLayout.vue'),
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('../views/MainDashboard.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewDayReport)) {
                return next({ name: 'Appointments' })
              }
              next()
            }
          },
          {
            path: '/day-report',
            name: 'DayReport',
            component: () => import('../views/DayReport.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewDayReport)) {
                return next({ name: 'Appointments' })
              }
              const appStore = useAppStore()
              appStore.load()
              const appointmentStore = useAppointmentStore()
              const employeeStore = useEmployeeStore()
              Promise.all([
                appointmentStore.fetchAppointments(isoDate(new Date())),
                employeeStore.fetchEmployees()
              ])
                .then(() => {
                  next()
                })
                .catch((err) => {
                  console.error(err)
                  return next({ name: 'Dashboard' })
                })
                .finally(() => appStore.unload())
            }
          },
          {
            path: '/customers/:id',
            name: 'Customer',
            component: () => import('../views/CustomerCard.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewCustomer)) {
                return next({ name: 'Customers' })
              }
              const appStore = useAppStore()
              appStore.load()
              const customerStore = useCustomerStore()
              customerStore
                .loadCustomer(to.params.id)
                .then(() => next())
                .catch((err) => {
                  console.error(err)
                  return next({ name: 'Customers' })
                })
                .finally(() => appStore.unload())
            }
          },
          {
            path: '/customers',
            name: 'Customers',
            component: () => import('../views/CustomerList.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewCustomer)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/services',
            name: 'Services',
            component: () => import('../views/ServiceList.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewServices)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/booking-page',
            name: 'BookingPage',
            component: () => import('../views/BookingPage.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ManageBookingPage)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/appointments',
            name: 'Appointments',
            component: () => import('../views/AppointmentsCalendar.vue')
          },
          {
            path: '/reports',
            name: 'Reports',
            component: () => import('../views/MainDashboard.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ViewStatistics) ||
                userStore.isStarter
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings',
            name: 'Settings',
            component: () => import('../views/SettingsPage.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewSettings)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/general',
            name: 'GeneralSettings',
            component: () => import('../views/settings/GeneralSettings.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewSettings)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/business',
            name: 'BusinessSettings',
            component: () => import('../views/settings/BusinessSettings.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ManageBusinessInfo)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/opening-hours',
            name: 'OpeningHoursSettings',
            component: () => import('../views/settings/OpeningHours.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ViewSettings)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/employees',
            name: 'EmployeesList',
            component: () => import('../views/settings/EmployeeList.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ManageSchedules) ||
                userStore.isTeam === false
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/schedule',
            name: 'Schedule',
            component: () => import('../views/settings/EmployeeSchedules.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ManageSchedules) ||
                userStore.isTeam === false
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/employees/:id',
            name: 'ViewEmployee',
            component: () => import('../views/settings/EmployeePage.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ManageSchedules)) {
                return next({ name: 'Dashboard' })
              }
              const appStore = useAppStore()
              appStore.load()
              const employeeStore = useEmployeeStore()
              const serviceStore = useServiceStore()
              employeeStore
                .fetchEmployee(to.params.id)
                .then(() => {
                  serviceStore.fetchServices().finally(() => {})
                  next()
                })
                .catch((err) => {
                  console.error(err)
                  return next({ name: 'EmployeesList' })
                })
                .finally(() => appStore.unload())
            }
          },
          {
            path: '/settings/booking-page',
            name: 'BookingPageSettings',
            component: () => import('../views/settings/BookingPageSettings.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ManageBookingPage)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/notifications',
            name: 'NotificationSettings',
            component: () => import('../views/settings/NotificationSettings.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ManageNotifications)) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/settings/sms',
            name: 'SmsSettings',
            component: () => import('../views/settings/SmsSettings.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ManageSms) ||
                userStore.isStarter
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/sms/overview',
            name: 'SmsOverview',
            component: () => import('../views/sms/Overview.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ManageSms) ||
                userStore.isStarter
              ) {
                return next({ name: 'Dashboard' })
              }
              const appStore = useAppStore()
              appStore.load()
              const notificationStore = useNotificationStore()
              const paymentStore = usePaymentStore()
              Promise.all([notificationStore.fetchSmsStats(), paymentStore.fetchSmsPayments()])
                .then(() => {
                  next()
                })
                .catch((err) => {
                  console.error(err)
                  return next({ name: 'Dashboard' })
                })
                .finally(() => appStore.unload())
            }
          },
          {
            path: '/sms/packages',
            name: 'PackageSelection',
            component: () => import('../views/sms/PackageSelection.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ManageSms) ||
                userStore.isStarter
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          },
          {
            path: '/account',
            name: 'AccountLayout',
            component: () => import('../views/account/AccountLayout.vue'),
            children: [
              {
                path: 'profile',
                name: 'Profile',
                component: () => import('../views/account/AccountProfile.vue'),
                beforeEnter: (to, from, next) => {
                  const permissionsStore = usePermissionsStore()
                  if (!permissionsStore.can(permissionsStore.Permissions.ManageAllEmployees)) {
                    return next({ name: 'Dashboard' })
                  }
                  const appStore = useAppStore()
                  appStore.load()
                  Promise.all([
                    useUserStore().refreshProfile(),
                    useEmployeeStore().fetchEmployees()
                  ])
                    .then(() => next())
                    .catch(() => next({ name: 'Dashboard' }))
                    .finally(() => appStore.unload())
                }
              },
              {
                path: 'password',
                name: 'Password',
                component: () => import('../views/account/PasswordChange.vue')
              },
              {
                path: 'settings',
                name: 'AccountSettings',
                component: () => import('../views/account/AccountSettings.vue'),
                beforeEnter: (to, from, next) => {
                  const permissionsStore = usePermissionsStore()
                  if (!permissionsStore.can(permissionsStore.Permissions.ManageAllEmployees)) {
                    return next({ name: 'Dashboard' })
                  }
                  const appStore = useAppStore()
                  const serviceStore = useServiceStore()
                  const employeeStore = useEmployeeStore()
                  const userStore = useUserStore()
                  appStore.load()
                  Promise.all([
                    userStore.refreshProfile(),
                    serviceStore.fetchServices(),
                    employeeStore.fetchEmployee(userStore.userId)
                  ])
                    .then(() => {
                      next()
                    })
                    .catch(() => next({ name: 'Dashboard' }))
                    .finally(() => appStore.unload())
                }
              },
              {
                path: 'subscription',
                name: 'Subscription',
                component: () => import('../views/account/SubscriptionManagement.vue'),
                beforeEnter: (to, from, next) => {
                  const permissionsStore = usePermissionsStore()
                  if (!permissionsStore.can(permissionsStore.Permissions.ChangeSubscription)) {
                    return next({ name: 'Dashboard' })
                  }
                  const appStore = useAppStore()
                  appStore.load()
                  useUserStore()
                    .fetchClientInfo()
                    .then(() => next())
                    .catch(() => next({ name: 'Dashboard' }))
                    .finally(() => appStore.unload())
                }
              },
              {
                path: 'delete',
                name: 'DeleteAccount',
                component: () => import('../views/account/DeleteAccount.vue'),
                beforeEnter: (to, from, next) => {
                  const permissionsStore = usePermissionsStore()
                  if (!permissionsStore.can(permissionsStore.Permissions.ChangeSubscription)) {
                    return next({ name: 'Dashboard' })
                  }
                  next()
                }
              }
            ]
          },
          {
            path: '/packages',
            name: 'Packages',
            component: () => import('../views/PackageSelection.vue'),
            beforeEnter: (to, from, next) => {
              const permissionsStore = usePermissionsStore()
              if (!permissionsStore.can(permissionsStore.Permissions.ChangeSubscription)) {
                return next({ name: 'Dashboard' })
              }
              const appStore = useAppStore()
              appStore.load()
              Promise.all([useUserStore().fetchClientInfo(), appStore.fetchPackages()])
                .then(() => next())
                .catch(() => next({ name: 'Dashboard' }))
                .finally(() => appStore.unload())
            }
          },
          {
            path: '/reports',
            name: 'Reports',
            component: () => import('../views/ReportsPage.vue'),
            beforeEnter: (to, from, next) => {
              const userStore = useUserStore()
              const permissionsStore = usePermissionsStore()
              if (
                !permissionsStore.can(permissionsStore.Permissions.ViewStatistics) ||
                userStore.isStarter
              ) {
                return next({ name: 'Dashboard' })
              }
              next()
            }
          }
        ]
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import('../views/NotFound.vue')
      }
    ]
  })

  function getDateLocaleByLocaleString(locale) {
    if (locale === 'el') {
      return el
    }
    return enUS
  }

  router.beforeEach(async (to, from, next) => {
    const locale = getStartingLocale()

    setDefaultOptions({ locale: getDateLocaleByLocaleString(locale) })

    // load locale messages
    if (!i18n.global.availableLocales.includes(locale)) {
      await loadLocaleMessages(i18n, locale)
    }

    // set i18n language
    setI18nLanguage(i18n, locale)

    return next()
  })

  return router
}

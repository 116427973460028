import { useFetch } from '@/client.js'

class CustomersService {
  search = (search, page, amount, sort, direction) => useFetch(`customers?search=${search}&page=${page}&amount=${amount}&sort=${sort}&direction=${direction}`).get().json()
  create = (customer) => useFetch('customers').post(customer).json()
  update = (customer) => useFetch(`customers/${customer.id}`).put(customer)
  getById = (id) => useFetch(`customers/${id}`).get().json()
  toggleCommunication = (id) => useFetch(`customers/${id}/communication`).patch()
  toggleBlacklist = (id) => useFetch(`customers/${id}/blacklist`).patch()
  delete = (id) => useFetch(`customers/${id}`).delete()
}

export default new CustomersService()
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePermissionsStore = defineStore('permissions', () => {
  const Permissions = Object.freeze({
    BookAppointments: 'BookAppointments',
    ApplyDiscounts: 'ApplyDiscounts',
    AccessOtherCalendars: 'AccessOtherCalendars',
    ManageBlockTimes: 'ManageBlockTimes',
    ViewDayReport: 'ViewDayReport',
    ViewCustomer: 'ViewCustomer',
    ViewRevenue: 'ViewRevenue',
    ViewServices: 'ViewServices',
    ManageServices: 'ManageServices',
    ManageBookingPage: 'ManageBookingPage',
    ViewStatistics: 'ViewStatistics',
    ViewSettings: 'ViewSettings',
    ManageBusinessInfo: 'ManageBusinessInfo',
    ManageNotifications: 'ManageNotifications',
    ViewEmployees: 'ViewEmployees',
    ManageAllEmployees: 'ManageAllEmployees',
    ManageSchedules: 'ManageSchedules',
    ChangeSubscription: 'ChangeSubscription',
    ManageSms: 'ManageSms'
  })

  const Roles = Object.freeze({
    Viewer: 'ROLE_VIEWER',
    User: 'ROLE_USER',
    Receptionist: 'ROLE_RECEPTIONIST',
    Manager: 'ROLE_MANAGER',
    Admin: 'ROLE_ADMIN'
  })
  const currentRole = ref(localStorage.getItem('role') || Roles.Viewer)
  const currentPermissions = ref(JSON.parse(localStorage.getItem('permissions')) || [])

  const can = (permission) => {
    return currentPermissions.value.includes(permission)
  }

  return {
    Permissions,
    Roles,
    currentRole,
    currentPermissions,
    can
  }
})

import { ref } from 'vue'
import { defineStore } from 'pinia'
import PaymentsService from '@/services/PaymentsService.js'

export const usePaymentStore = defineStore('payment', () => {
  const smsPayments = ref([])

  const fetchSmsPayments = async () => {
    const { data, error } = await PaymentsService.getSmsPayments()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        smsPayments.value = data.value
        resolve(data.value)
      }
    })
  }

  return { fetchSmsPayments, smsPayments }
})

<template>
  <div :class="['modal', {'is-active': appStore.confirmationDialog.show}]">
    <div class="modal-background"></div>
    <div class="modal-card">
      <div class="modal-card-body">
        <div class="is-flex">
          <CustomIcon v-model:icon="appStore.confirmationDialog.color" size="20"
                      :color="appStore.confirmationDialog.color"></CustomIcon>
          <div class="dialog-message ml-5">
            <p class="is-size-4 has-text-weight-semibold">{{ appStore.confirmationDialog.title }}</p>
            <p v-html="appStore.confirmationDialog.message"></p>
            <div class="dialog-buttons mt-6">
              <BasicButton color="secondary" outlined :expanded="appStore.isSmallScreen" @click="close">
                {{ $t('appointments.cancel') }}
              </BasicButton>
              <BasicButton :color="appStore.confirmationDialog.color" :expanded="appStore.isSmallScreen"
                           @click="confirm">
                {{ $t('appointments.delete') }}
              </BasicButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CustomIcon from '@/components/graphics/icons/CustomIcon.vue'
import BasicButton from '@/components/form/BasicButton.vue'
import { useAppStore } from '@/stores/app.js'

const appStore = useAppStore()

const close = () => {
  appStore.dismissConfirmationDialog()
}

const confirm = () => {
  appStore.confirmationDialog.callback()
  appStore.dismissConfirmationDialog()
}
</script>

<style scoped lang="scss">
.dialog-message {
  display: flex;
  flex-direction: column;

  .dialog-buttons {
    display: flex;
    justify-content: flex-end;

    .button {
      margin: 0 0.5rem;
    }

    @include touch {
      display: block;
    }
  }
}
</style>
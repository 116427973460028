import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import getBrowserLocale from '@/utils/i18n/get-browser-locale.js'

export const SUPPORT_LOCALES = ['en', 'el']

let translations = null

export function getStartingLocale () {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })

  if (localStorage.getItem('language')) {
    return localStorage.getItem('language')
  } else if (browserLocale === 'en' || browserLocale === 'el') {
    return browserLocale
  } else {
    return import.meta.env.VITE_APP_I18N_LOCALE || 'en'
  }
}

export function setupI18n (options = { locale: 'en' }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  translations = i18n
  return i18n
}

export function setI18nLanguage (i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }

  document.querySelector('html').setAttribute('lang', locale)
}

export function setGlobalLocale (i18n, locale) {
  i18n.locale = locale
}

export async function loadLocaleMessages (i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export function trans (key, attributes = []) {
  return translations.global.t(key, attributes)
}
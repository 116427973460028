<template>
  <span class="icon-text" v-if="text">
    <span class="icon" :class="{'is-flipped-horizontally': flippedHorizontally}">
      <InlineSvg :src="iconUrl" :class="color ? `has-text-${color}` : ''"
                 :style="{width: `${size}px`, height: `${size}px`}"></InlineSvg>
    </span>
    <span :class="[`has-text-${color}`, {'ml-3': spacey}]">{{ text }}<slot name="text"></slot></span>
  </span>
  <span class="icon" :class="{'is-flipped-horizontally': flippedHorizontally}" v-else>
    <InlineSvg :src="iconUrl" :class="color ? `has-text-${color}` : ''"
               :style="{width: `${size}px`, height: `${size}px`}"></InlineSvg>
  </span>
</template>
<script setup>
import InlineSvg from 'vue-inline-svg'
import { computed, watch } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  text: {
    type: [String, Number, null],
    default: null
  },
  size: {
    type: String,
    default: 'inherit'
  },
  spacey: {
    type: Boolean,
    default: true
  },
  flippedHorizontally: {
    type: Boolean,
    default: false
  }
})

const iconUrl = computed(() => new URL(`/src/assets/icons/${props.icon}.svg`, import.meta.url).href)

watch(() => props.icon, (icon) => {
  iconUrl.value = new URL(`/src/assets/icons/${icon}.svg`, import.meta.url).href
})
</script>

<style scoped lang="scss">
.is-flipped-horizontally {
  :deep(svg) {
    transform: rotate(180deg);
  }
}
</style>
import { ref } from 'vue'
import { defineStore } from 'pinia'
import NotificationsService from '@/services/NotificationsService.js'

export const useNotificationStore = defineStore('notification', () => {
  const smsStats = ref({
    sms: [],
    monthToMonthDiff: 0,
    previousMonthSms: 0,
    currentMonthSms: 0,
    availableSms: 0,
    monthlyAverage: 0
  })

  const fetchSmsStats = async () => {
    const { data, error } = await NotificationsService.getSmsStats()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        smsStats.value = data.value
        resolve(data.value)
      }
    })
  }

  return { fetchSmsStats, smsStats }
})

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { addMinutes, isAfter, isBefore, isSameDay } from 'date-fns'
import AppointmentsService from '@/services/AppointmentsService.js'
import { useCustomerStore } from '@/stores/customer.js'

export const useAppointmentStore = defineStore('appointment', () => {
  const customerStore = useCustomerStore()

  const newAppointment = {
    id: null,
    customer: null,
    notes: '',
    status: 'new',
    startDate: new Date(),
    services: []
  }
  const newRecurringAppointment = {
    appointment: false,
    frequency: 0,
    repetitions: 0,
    endDate: null,
    appointmentGroup: null
  }

  const appointment = ref(structuredClone(newAppointment))
  const recurring = ref(structuredClone(newRecurringAppointment))
  const snapshot = ref()
  const recurringSnapshot = ref()
  const appointments = ref([])
  const recurringAppointments = ref([])

  const isNewAppointment = computed(() => appointment.value.status === 'new')
  const hasAppointmentChanged = computed(
    () =>
      snapshot.value !== JSON.stringify([appointment.value.startDate, appointment.value.services])
  )
  const wantsRecurringAppointment = computed(() => recurring.value.appointment === true)
  const todaysAppointments = computed(() =>
    appointments.value.filter((appointment) => {
      return isSameDay(new Date(), appointment.startDate) && !appointment.isFake
    })
  )
  const todaysCompletedAppointments = computed(() =>
    todaysAppointments.value.filter((appointment) => {
      return appointment.status === 'done' && !appointment.isFake
    })
  )
  const todaysCancelledAppointments = computed(() =>
    todaysAppointments.value.filter((appointment) => {
      return appointment.status === 'cancelled' && !appointment.isFake
    })
  )
  const todaysNoShowAppointments = computed(() =>
    todaysAppointments.value.filter((appointment) => {
      return appointment.status === 'no-show' && !appointment.isFake
    })
  )
  const todaysPendingAppointments = computed(() =>
    todaysAppointments.value.filter((appointment) => {
      return appointment.status === 'new' && !appointment.isFake
    })
  )
  const todaysIncome = computed(() => {
    let total = 0
    todaysCompletedAppointments.value.forEach((appointment) => {
      if (appointment.isFake) {
        return
      }
      total += appointment.totalCost
    })
    return total
  })

  const initAppointment = () => {
    let now = new Date()
    const minute = now.minute
    if (minute !== 0 && minute % 5) {
      const remainder = minute % 5
      now = addMinutes(now, 5 - remainder)
    }
    appointment.value = structuredClone(newAppointment)
    appointment.value.startDate = now
  }

  const setAppointment = (appt) => {
    appointment.value = appt
  }

  const clearRecurring = () => {
    recurring.value = structuredClone(newRecurringAppointment)
  }

  const saveAppointment = async () => {
    let appt, err
    if (appointment.value.id) {
      const { error } = await AppointmentsService.update(appointment.value)
      err = error.value
    } else {
      const { data, error } = await AppointmentsService.create(appointment.value)
      appt = data.value
      err = error.value
      customerStore.lastCustomerUsed = appt.customer
    }
    return new Promise((resolve, reject) => {
      if (err) {
        reject(err)
      } else {
        resolve(appt)
      }
    })
  }

  const deleteAppointment = async (id) => {
    const { error } = await AppointmentsService.delete(id, recurring.value)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const createRecurringAppointments = async (id) => {
    const { data, error } = await AppointmentsService.createRecurring(id, recurring.value)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve(data)
      }
    })
  }

  const fetchAppointments = async (dateFrom, dateTo = null) => {
    let appts
    let err
    if (dateTo === null) {
      const { data, error } = await AppointmentsService.getByDate(dateFrom)
      appts = data.value
      err = error.value
    } else {
      const { data, error } = await AppointmentsService.getByRange(dateFrom, dateTo)
      appts = data.value
      err = error.value
    }

    return new Promise((resolve, reject) => {
      if (err) {
        reject(err)
      } else {
        appointments.value = appts
        if (appointments.value.length > 0) {
          customerStore.lastCustomerUsed = appointments.value.sort((a, b) => {
            if (isAfter(new Date(a.createdOn), new Date(b.createdOn))) {
              return -1
            }
            if (isBefore(new Date(a.createdOn), new Date(b.createdOn))) {
              return 1
            }
            return 0
          })[0].customer
        }
        resolve(appts)
      }
    })
  }

  const takeSnapshot = () => {
    snapshot.value = JSON.stringify([appointment.value.startDate, appointment.value.services])
  }

  const clearSnapshot = () => {
    snapshot.value = undefined
  }

  const takeRecurringSnapshot = () => {
    recurringSnapshot.value = JSON.stringify(recurring.value)
  }

  const restoreRecurringSnapshot = () => {
    recurring.value = recurringSnapshot.value
      ? JSON.parse(recurringSnapshot.value)
      : structuredClone(newRecurringAppointment)
  }

  const clearRecurringSnapshot = () => {
    recurringSnapshot.value = undefined
  }

  const toggleService = (service, selectedEmployee = null) => {
    if (!selectedEmployee) {
      selectedEmployee = service.employees[0]
    }
    if (appointment.value.services.filter((svc) => svc.service.id === service.id).length > 0) {
      appointment.value.services = appointment.value.services.filter(
        (svc) => svc.service.id !== service.id
      )
    } else {
      const validEmployees = service.employees.filter((emp) => emp.id === selectedEmployee.id)
      appointment.value.services.push({
        service: service,
        duration: service.duration,
        price: service.price,
        employee:
          selectedEmployee && validEmployees.length > 0 ? validEmployees[0] : service.employees[0]
      })
    }
  }

  const updateService = (service) => {
    appointment.value.services.forEach((svc, idx) => {
      if (svc.service.id === service.service.id) {
        appointment.value.services[idx].duration = service.duration
        appointment.value.services[idx].price = service.price
        appointment.value.services[idx].employee = service.employee
      }
    })
  }

  const fetchRecurring = async (id) => {
    const { data, error } = await AppointmentsService.getRecurring(id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        recurringAppointments.value = data.value.appointments
        resolve(data)
      }
    })
  }

  const deleteRecurring = async (id) => {
    const { error } = await AppointmentsService.deleteRecurring(id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const toggleFake = async () => {
    const { error } = await AppointmentsService.toggleFake(appointment.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        appointment.value.isFake = !appointment.value.isFake
        resolve()
      }
    })
  }

  return {
    appointment,
    recurring,
    recurringSnapshot,
    snapshot,
    isNewAppointment,
    hasAppointmentChanged,
    wantsRecurringAppointment,
    initAppointment,
    clearRecurring,
    saveAppointment,
    createRecurringAppointments,
    todaysAppointments,
    todaysCompletedAppointments,
    todaysCancelledAppointments,
    todaysNoShowAppointments,
    todaysPendingAppointments,
    todaysIncome,
    fetchAppointments,
    appointments,
    setAppointment,
    takeSnapshot,
    clearSnapshot,
    deleteAppointment,
    takeRecurringSnapshot,
    clearRecurringSnapshot,
    restoreRecurringSnapshot,
    toggleService,
    updateService,
    fetchRecurring,
    deleteRecurring,
    recurringAppointments,
    toggleFake
  }
})
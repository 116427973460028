<template>
  <div :class="{'no-scroll': appStore.loading}">
    <RouterView/>
    <LoaderAnimation></LoaderAnimation>
    <SnackBar></SnackBar>
    <ConfirmationDialog></ConfirmationDialog>
    <ReloadPrompt></ReloadPrompt>
  </div>
</template>

<script setup>
import { RouterView } from 'vue-router'
import LoaderAnimation from '@/components/LoaderAnimation.vue'
import SnackBar from '@/components/SnackBar.vue'
import { useAppStore } from '@/stores/app.js'
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import { useHead } from 'unhead'

const appStore = useAppStore()

useHead({
  meta: [
    { name: 'robots', content: import.meta.env.VITE_APP_ENVIRONMENT !== 'production' ? 'noindex' : '' }
  ]
})
</script>

<style lang="scss">
@import "@/assets/scss/global";
#app {
  background: #fff;
  background-size: cover;
}

.no-scroll {
  overflow: hidden;
}
</style>
<template>
  <transition name="toast">
    <article class="message is-info" v-if="needRefresh">
      <div class="message-body is-flex">
        <div class="is-flex-grow-1">
          <p class="has-text-weight-semibold is-size-4">
            {{ $t('app_update') }}
          </p>
          {{ $t('click_here_to_update') }}
        </div>
        <BasicButton class="ml-4" @click.prevent="updateServiceWorker()">{{ $t('refresh') }}</BasicButton>
      </div>
    </article>
  </transition>
</template>

<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'
import BasicButton from '@/components/form/BasicButton.vue'

const {
  needRefresh,
  updateServiceWorker
} = useRegisterSW()
</script>

<style scoped lang="scss">
.message {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  top: auto;
  transform: translate(-50%, 0);
  box-shadow: $shadow-lg;
  border: 1px solid $border-color;
  @include touch {
    width: 90%;
  }
}

/* enter transitions */
.toast-enter-from {
  opacity: 0;
  transform: translate(-50%, 100px);
}

.toast-enter-to {
  opacity: 1;
  transform: translate(-50%, 0);
}

.toast-enter-active {
  transition: all 0.3s ease;
}

/* leave transitions */
.toast-leave-from {
  opacity: 1;
  transform: translate(-50%, 0);
}

.toast-leave-to {
  opacity: 0;
  transform: translate(-50%, 100px);
}

.toast-leave-active {
  transition: all 0.3s ease;
}

@keyframes wobble {
  0% {
    transform: translateY(100px);
    opacity: 0
  }
  50% {
    transform: translateY(0px);
    opacity: 1
  }
  60% {
    transform: translateX(8px);
    opacity: 1
  }
  70% {
    transform: translateX(-8px);
    opacity: 1
  }
  80% {
    transform: translateX(4px);
    opacity: 1
  }
  90% {
    transform: translateX(-4px);
    opacity: 1
  }
  100% {
    transform: translateX(0px);
    opacity: 1
  }
}
</style>
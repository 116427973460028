<template>
  <div class="loader-container" v-if="appStore.loading">
    <InlineSvg :src="logoUrl" class="has-text-white rotate-vert-center"
               style="width: 100px; height: 100px;"></InlineSvg>
  </div>
</template>

<script setup>
import { useAppStore } from '@/stores/app.js'
import InlineSvg from 'vue-inline-svg'

const appStore = useAppStore()
const logoUrl = new URL(`/src/assets/icons/appointr_icon.svg`, import.meta.url).href
</script>

<style lang="scss">
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(12, 17, 29, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.rotate-vert-center {
  -webkit-animation: rotate-vert-center 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: rotate-vert-center 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

</style>

<template>
  <transition name="toast">
    <article :class="['message', `is-${appStore.snackbar.color}`]" v-show="appStore.snackbar.show">
      <div class="message-body is-flex">
        <div>
          <component :is="iconComponent" class="mt-1 mr-3"></component>
        </div>
        <div class="is-flex-grow-1">
          <p class="has-text-weight-semibold is-size-4" v-if="appStore.snackbar.headerText">
            {{ appStore.snackbar.headerText }}
          </p>
          {{ appStore.snackbar.content }}
        </div>
      </div>
    </article>
  </transition>
</template>

<script setup>
import { useAppStore } from '@/stores/app.js'
import DangerIcon from '@/components/graphics/icons/DangerIcon.vue'
import SuccessIcon from '@/components/graphics/icons/SuccessIcon.vue'
import WarningIcon from '@/components/graphics/icons/WarningIcon.vue'
import { computed } from 'vue'

const appStore = useAppStore()

const iconComponent = computed(() => {
  switch (appStore.snackbar.color) {
    case 'success':
      return SuccessIcon
    case 'warning':
      return WarningIcon
    case 'danger':
      return DangerIcon
  }
})
</script>

<style lang="scss">
.message {
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: translate(-50%, 0);
  box-shadow: $shadow-lg;
  border: 1px solid $border-color;
  z-index: 10000;
  @include touch {
    width: 90%;
  }
}

/* enter transitions */
.toast-enter-from {
  opacity: 0;
  transform: translate(-50%, -100px);
}

.toast-enter-to {
  opacity: 1;
  transform: translate(-50%, 0);
}

.toast-enter-active {
  transition: all 0.3s ease;
}

/* leave transitions */
.toast-leave-from {
  opacity: 1;
  transform: translate(-50%, 0);
}

.toast-leave-to {
  opacity: 0;
  transform: translate(-50%, -100px);
}

.toast-leave-active {
  transition: all 0.3s ease;
}

@keyframes wobble {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  60% {
    transform: translateX(8px);
    opacity: 1;
  }
  70% {
    transform: translateX(-8px);
    opacity: 1;
  }
  80% {
    transform: translateX(4px);
    opacity: 1;
  }
  90% {
    transform: translateX(-4px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
import { createFetch } from '@vueuse/core'
import { useUserStore } from '@/stores/user.js'

export const useFetch = createFetch({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URL || '',
  options: {
    beforeFetch ({ options }) {
      const token = localStorage.getItem('token')
      options.headers.Authorization = `Bearer ${token}`

      return { options }
    },
    updateDataOnError: true,
    onFetchError (ctx) {
      if (!ctx.data) {
        return ctx
      }
      if (ctx.data.code === 401 && !ctx.response.url.includes('login_check')) {
        const userStore = useUserStore()
        userStore.logoutUser().then(() => {
          window.location.replace('/login')
        })
      }
      if (ctx.response.status === 403) {
        window.location.replace('/account/subscription')
      }

      return ctx
    }
  },
  fetchOptions: {
    mode: 'cors'
  }
})